<template>
    <v-container>
      <v-row>
        <div>

          <b-input-group>
            <b-button style="width: auto" @click="addUser()"
              >Test Add User</b-button
            >
            <b-button
              class="offers-button"
              style="width: auto"
              @click="postUser()"
              >Test Post to User</b-button
            >
          </b-input-group>
        </div>
      </v-row>

      <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  
    </v-container>
  </template>
  
  <script>
//   import axios from "axios";
  import CommonServices from "../service/CommonServices";
  
  export default {
    data() {
      return {
 
        items: [],
        snackbar: false,
        snackColor: "",
        snackText: "",
        error: false,

        userItem: {
          id: "",
          uid: "3772Curt",
          first_name: "Curt",
          last_name: "TEst2",
          full_name: "Curt TEst2",
          phone: "7855543050",
          email: "drewlynch11@gmail.com",
          user_type_id: 7,
          product_id: 3655,
          django_pwd: "78555430503772",
        },

      };
    },
  
   
    created() {

  
          }, 
  
    methods: {
        postUser(){
            CommonServices.postData("Users", this.userItem)
                .then((response) => {
                console.log("User Item Response: ", response.id);
                })
                .catch((error) => {
                console.log("Post Activity Error: ", error);
                });
        },

        addUser(){
            CommonServices.addRecord("Users", this.userItem)
            .then((response) => {
              this.items.push(response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        },
    },
  };
  </script>
  
  <style src="../css/styles.css" scoped></style>
  